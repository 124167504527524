import { FC, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import getConfig from "../../../src/config";
import { providerError } from "src/adapters/error";
import { useEnvContext } from "src/contexts/env.context";
import { ProviderError } from "src/domain";
import { routes } from "src/routes";
import { useNetworkErrorStyles } from "src/views/network-error/network-error.styles";
import { Typography } from "src/views/shared/typography/typography.view";

export const NetworkError: FC = () => {
  const classes = useNetworkErrorStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const env = useEnvContext();
  const config = getConfig();

  useEffect(() => {
    if (env) {
      navigate(routes.home.path);
    }
  }, [env, navigate]);

  const parsedProviderError = providerError.safeParse(state);

  return parsedProviderError.success ? (
    <div className={classes.wrapper}>
      <img className={classes.logo} src={config?.brand.logo.dark || 'https://f005.backblazeb2.com/file/tracehawk-prod/logo/Zeeve/Dark.png'} />
      <div className={classes.textBox}>
        <Typography type="h1">Network Error</Typography>
        <Typography type="body1">
          {parsedProviderError.data === ProviderError.Ethereum
            ? "We cannot connect to the Ethereum node."
            : "We cannot connect to the GPT Mainnet node."}
        </Typography>
        <Typography type="body2">It will be operative again soon</Typography>
      </div>
      <button className={classes.button} onClick={() => navigate(routes.home.path)} type="button">
        Try again
      </button>
    </div>
  ) : (
    <Navigate to={routes.home.path} />
  );
};
